import { del, get, post } from 'helpers/fetch'

export async function postInvite(organization, payload) {
  const response = await post(`/organizations/${organization}/invites`, payload)

  return response
}

export async function getInvites(organization, ctx) {
  const url = `/organizations/${organization}/invites`
  const response = await get(url, ctx)

  return response
}

export async function getInvite(invite, ctx) {
  const response = await get(`/invites/${invite}`, ctx)

  return response
}

export async function deleteInvite(organization, invite) {
  const url = `/organizations/${organization}/invites/${invite}`
  const response = await del(url)

  return response
}

export async function postSendInvite(organization, invite) {
  const response = await post(`/organizations/${organization}/invites/${invite}/send_email`)

  return response
}
