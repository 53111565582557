import React, { useContext, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { Field, Form as FormikForm, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { toLower } from 'lodash'
import { object, string } from 'yup'
import { Trans, withTranslation } from 'i18n'
import { postPasswordReset } from 'api/auth'
import AlertContext from 'components/AlertContext'

const PasswordReset = ({ t }) => {
  const [open, setOpen] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const initialValues = { email: '' }
  const validationSchema = object().shape({
    email: string()
      .email(t('common:validation.email'))
      .required(t('common:validation.required')),
  })

  async function resetPassword(values, formikBag) {
    const { email } = values
    const payload = { email: toLower(email) }
    const response = await postPasswordReset(payload)

    if (response.ok) {
      setOpen(!open)

      setAlert({
        variant: 'info',
        message: t('login:passwordReset.messages.success'),
      })
    } else {
      const { status } = response

      formikBag.setSubmitting(false)

      setAlert({
        variant: 'error',
        message: t('login:passwordReset.messages.error', { status }),
      })
    }
  }

  return (
    <>
      <Button color='secondary' onClick={_ => setOpen(!open)}>
        {t('login:forgotPassword')}
      </Button>
      <Dialog open={open} onClose={_ => setOpen(!open)} maxWidth='xs' fullWidth>
        <DialogTitle>{t('login:passwordReset.title')}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={resetPassword}
          onReset={_ => setOpen(!open)}
        >
          {formikBag => {
            const { isValid, isSubmitting } = formikBag

            return (
              <FormikForm>
                <DialogContent>
                  <DialogContentText>
                    <Trans i18nKey='login:passwordReset.content'>
                      text <br /> text
                    </Trans>
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        type='email'
                        name='email'
                        label={t('common:fields.email')}
                        component={TextField}
                        variant='outlined'
                        fullWidth
                        autoFocus
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type='reset'
                      color='secondary'
                      disabled={isSubmitting}
                    >
                      {t('common:actions.cancel')}
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={!isValid || isSubmitting}
                    >
                      {isSubmitting
                        ? t('common:actions.send.doing')
                        : t('common:actions.send.do')}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </FormikForm>
            )
          }}
        </Formik>
      </Dialog>
    </>
  )
}

export default withTranslation(['common', 'login'])(PasswordReset)
