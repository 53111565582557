import React, { useContext, useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/styles'
import EmailIcon from '@material-ui/icons/Email'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Field, Form as FormikForm, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { toLower } from 'lodash'
import { object, string } from 'yup'
import { withTranslation } from 'i18n'
import { postLogin } from 'api/auth'
import AlertContext from 'components/AlertContext'
import LoginContext from 'components/Login/LoginContext'

const useStyles = makeStyles((theme) => ({
  item: {
    margin: theme.spacing(2, 0),
  },
  endAdornment: {
    cursor: 'pointer',
  },
}))

const Form = ({ t }) => {
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles()
  const { setAlert } = useContext(AlertContext)
  const { token, email } = useContext(LoginContext)
  const initialValues = token
    ? { email, password: '', remember: false, token }
    : { email: '', password: '', remember: false }
  const validationSchema = object().shape({
    email: string()
      .email(t('common:validation.email'))
      .required(t('common:validation.required')),
    password: string().required(t('common:validation.required')),
  })

  async function login(values, formikBag) {
    const { email, ...form } = values
    const payload = { email: toLower(email), ...form }
    const response = await postLogin(payload)

    if (!response.ok) {
      const { status } = response
      const variant = 'error'

      formikBag.setSubmitting(false)

      if (status === 401) {
        setAlert({
          variant,
          message: t('login:messages.error', { context: '401' }),
        })
      } else {
        setAlert({ variant, message: t('login:messages.error', { status }) })
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={login}
    >
      {(formikBag) => {
        const { isValid, isSubmitting } = formikBag

        return (
          <FormikForm>
            <Grid container justifyContent='space-around'>
              <Grid item xs={10} className={classes.item}>
                <Field
                  type='email'
                  name='email'
                  component={TextField}
                  variant='outlined'
                  label={t('common:fields.email')}
                  fullWidth
                  autoFocus
                  InputProps={{
                    endAdornment: <EmailIcon />,
                  }}
                />
              </Grid>
              <Grid item xs={10} className={classes.item}>
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  component={TextField}
                  variant='outlined'
                  label={t('common:fields.password.label')}
                  fullWidth
                  InputProps={{
                    endAdornment: showPassword ? (
                      <Tooltip title={t('common:fields.password.hide')}>
                        <VisibilityOffIcon
                          onClick={() => setShowPassword(false)}
                          className={classes.endAdornment}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('common:fields.password.show')}>
                        <VisibilityIcon
                          onClick={() => setShowPassword(true)}
                          className={classes.endAdornment}
                        />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.item}>
                <Field
                  type='checkbox'
                  name='remember'
                  component={CheckboxWithLabel}
                  Label={{ label: t('login:remember') }}
                  color='primary'
                />
              </Grid>
              <Grid item xs={12} className={classes.item}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? t('login:validating') : t('login:signIn')}
                </Button>
              </Grid>
            </Grid>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default withTranslation(['common', 'login'])(Form)
