import React, { Component } from 'react'
import AlertContext from 'components/AlertContext'

const withAlertContext = WrappedComponent =>
  class extends Component {
    static async getInitialProps(ctx) {
      const componentProps = WrappedComponent.getInitialProps
        ? await WrappedComponent.getInitialProps(ctx)
        : {}

      return { ...componentProps }
    }

    render() {
      return (
        <AlertContext.Consumer>
          {context => (
            <WrappedComponent alertContext={context} {...this.props} />
          )}
        </AlertContext.Consumer>
      )
    }
  }

export default withAlertContext
