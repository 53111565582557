import React, { Component } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import Router from 'next/router'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import cookies from 'js-cookie'
import moment from 'moment'
import { compose } from 'recompose'
import { withTranslation } from 'i18n'
import withAlertContext from 'hoc/withAlertContext'
import { getCookies } from 'helpers/cookies'
import { getInvite } from 'api/invites'
import Footer from 'layout/Footer'
import Form from 'components/Login/Form'
import LoginContext from 'components/Login/LoginContext'
import PasswordReset from 'components/Login/PasswordReset'

const styles = (theme) => ({
  center: {
    textAlign: 'center',
  },
  leftUpMd: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  rightUpMd: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  container: {
    height: '100vh',
    margin: '-58px 0 0',
  },
  paper: {
    height: '100%',
    padding: theme.spacing(4),
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-between',
    height: '100%',
  },
  logo: {
    position: 'relative',
  },
  logoUpXs: {
    width: `${theme.spacing(2)}em`,
  },
  logoXs: {
    width: `${theme.spacing(1) / 2}em`,
  },
  actions: {
    margin: theme.spacing(2, 0),
  },
  background: {
    height: '100%',
    backgroundImage:
      'url(https://info.mailtop.com.br/imagens/mailtop-app/login.jpg)',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
  },
  footer: {
    width: '100%',
  },
})

class Login extends Component {
  static async getInitialProps(ctx) {
    const { res, query } = ctx
    const { status, token } = query
    const invite = token
      ? await getInvite(token, ctx).then((response) => response.json())
      : {}
    const {
      authenticated,
      remember,
      access_expires_at,
      organization,
      timed_out,
    } = getCookies(ctx)
    const expirationTime = moment(access_expires_at)
    const now = moment(new Date())

    if (authenticated && remember && now.isBefore(expirationTime)) {
      if (organization) {
        if (res) {
          res.writeHead(302, { Location: '/home' })
          res.end()
        } else {
          Router.push('/home')
        }
      } else {
        if (res) {
          res.writeHead(302, { Location: '/organizations' })
          res.end()
        } else {
          Router.push('/organizations')
        }
      }
    }

    return {
      status,
      token,
      invite,
      timedOut: timed_out,
      namespacesRequired: ['login'],
    }
  }

  componentDidMount() {
    const { t, alertContext, status, timedOut } = this.props
    const { setAlert } = alertContext

    if (status) {
      if (status === 'verified') {
        setAlert({
          variant: 'success',
          message: t('messages.verified'),
        })
      }

      if (status === 'recovered') {
        setAlert({
          variant: 'success',
          message: t('messages.recovered'),
        })
      }
    }

    if (timedOut) {
      setAlert({
        variant: 'warning',
        message: t('messages.timedOut'),
      })

      cookies.remove('timed_out')
    }
  }

  render() {
    const { classes, t, token, invite } = this.props

    return (
      <LoginContext.Provider value={{ token, ...invite }}>
        <Head>
          <title>{t('page')}</title>
        </Head>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={6} lg={4} className={classes.center}>
                <Paper square className={classes.paper}>
                  <Grid className={classes.paperContent}>
                    <Hidden implementation='css' smUp>
                      <img
                        src='/static/images/mailtop/logo.svg'
                        alt='Mailtop logo'
                        className={clsx(classes.logo, classes.logoXs)}
                      />
                    </Hidden>
                    <Hidden implementation='css' only='xs'>
                      <img
                        src='/static/images/mailtop/wordmark.svg'
                        alt='Mailtop wordmark'
                        className={clsx(classes.logo, classes.logoUpXs)}
                      />
                    </Hidden>
                    <Form />
                    <Grid
                      container
                      justifyContent='center'
                      className={classes.actions}
                    >
                      <Grid item xs={12} md={5} className={classes.leftUpMd}>
                        <PasswordReset />
                      </Grid>
                      <Grid item xs={12} md={5} className={classes.rightUpMd}>
                        <Link href='/sign_up'>
                          <Button color='primary'>{t('signUp')}</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Hidden only='xs'>
                <Grid item sm={6} lg={8}>
                  <Grid className={classes.background} />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Footer className={classes.footer} />
          </Grid>
        </Grid>
      </LoginContext.Provider>
    )
  }
}

export default compose(
  withTranslation('login'),
  withStyles(styles),
  withAlertContext
)(Login)
